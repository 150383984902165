.cardBodyContainer {
    padding: 0px;
    .btnContainer {
      padding: 20px 7.5px 0px 7.5px;
    }
  }
  .orLable {
    padding-top: 7px;
  }
  .addNewBtn {
    max-width: 12.666667%;
  }
  .uploadBtn {
    padding-left: 5%;
  }
  .dowloadLable {
    text-align: end;
    color: blue !important;
    padding-top: 10px;
  }
  .inputField {
    padding: 15px 15px 10px 15px;
  }
  // .view-modal {
  //   min-width: 55%;
  // }
  .submitBtn {
    text-align: end;
    .btn {
      margin: 5px;
      .icon {
        margin: 5px;
      }
    }
  }
  
  .faq-modal-sub-container > div {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns:1fr 1fr;
    margin-bottom: 2rem;
  }
  
  .expiryDateValidation {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }
  
  .alertContainer {
    text-align: center;
  }
  
  .actionContainer {
    .icon {
      margin: 5px;
      cursor: pointer;
    }
  }
  
  .errorFlag {
    color: red;
  }
  
  h6 {
    word-break: break-word;
  }
  
  .addCategoryLabel {
    cursor: pointer;
    color: blue;
  }
  
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
.sidebarContainer {
    padding: 20px 15px 20px 15px !important;
    max-height: calc(100%);
    overflow-y: auto;
    background: #001937 !important;
    .sidebarNavContainer {
        overflow-y : unset !important
    }
    
    .headerContainer {
        text-align: center !important;
    }
}
